import React, { useEffect, useState } from 'react';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from '../../nexoClient';
import { Layout, Page } from '@nimbus-ds/patterns';
import { Box, Button, Table, Title } from '@nimbus-ds/components';
import axiosInstance from '../../axios';

const RejectedPage: React.FC = () => {
  const [rejectedSellers, setRejectedSellers] = useState<Seller[]>([]);

  interface Seller {
    store_id: string;
    nombre_tienda: string;
    origen_producto: string;
    tipo_oferta: string;
    registro_marca: string;
    nombre: string;
    telefono: string;
    email: string;
    url_tienda: string;
    url: string;
    seller_tag: string;
    status: string;
  }

  const headers = [
    'Id',
    'Nombre Tienda',
    'Origen producto',
    'Tipo oferta',
    'Registro marca',
    'Url',
    'Nombre',
    'Teléfono',
    'Email',
    'Acciones',
  ];

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    getListOfRejectedSellers();
  }, []);

  const getListOfRejectedSellers = async () => {
    console.log('obteniendo sellers rechazados...');
    try {
      const response = await axiosInstance.get('/rejected_sellers_list');
      console.log(response);
      if (response.data.status == true) {
        console.log(response.data.sellers);
        setRejectedSellers(response.data.sellers);
      }
    } catch (e) {
      console.error(e);
    }
    return;
  };

  const restoreSeller = async (store_id: any) => {
    console.log('restore seller store id : ' + store_id);
    const seller = rejectedSellers.find((s) => s.store_id == store_id);
    /*
         addToast({
           id: 'approve-seller-tag-fail',
           type: 'danger',
           text: 'El seller debe tener un tag para ser aprobado',
           duration: 4000,
         });
         */
    return;
  };

  return (
    <Page maxWidth="1500px" marginTop="18">
      <Page.Header alignItems="center" title="Sellers Rechazados" />
      <Page.Body>
        <Layout columns="1">
          <Layout.Section>
            <Box display="flex" flexDirection="column" gap="4" padding="4">
              <Title textAlign="center" as="h3">
                Lista de sellers rechazados
              </Title>
            </Box>
            <Table>
              <Table.Head>
                {headers.map((header) => (
                  <Table.Cell key={header}>{header}</Table.Cell>
                ))}
              </Table.Head>
              <Table.Body>
                {rejectedSellers.map((seller) => (
                  <Table.Row key={seller.store_id}>
                    <Table.Cell>{seller.store_id}</Table.Cell>
                    <Table.Cell>{seller.nombre_tienda}</Table.Cell>
                    <Table.Cell>{seller.origen_producto}</Table.Cell>
                    <Table.Cell>{seller.tipo_oferta}</Table.Cell>
                    <Table.Cell>{seller.registro_marca}</Table.Cell>
                    <Table.Cell>{seller.url ?? seller.url_tienda}</Table.Cell>
                    <Table.Cell>{seller.nombre}</Table.Cell>
                    <Table.Cell>{seller.telefono}</Table.Cell>
                    <Table.Cell>{seller.email}</Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() => restoreSeller(seller.store_id)}
                        appearance="neutral"
                      >
                        Restaurar
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
};

export default RejectedPage;
